import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from '../components/seo'
import './voyages.scss'
import Header from "../components/header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const EscapadesIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          No blog posts found. Add markdown posts to "content/escapades" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Escapades | Cité de l'évasion" />
      <div id="page_voyages">
        <h1 className="text_center">Nos sorties d'une journée</h1>
        <p className="sous_titre text_center">Voici quelques idées de sorties d'une journée en groupe</p>
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <div className="container section_articles">
                <li key={post.fields.slug}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                  {post.frontmatter.promo && <div className="promo">- {post.frontmatter.pourcentpromo} %</div>}
                  {post.frontmatter.coeur && <FontAwesomeIcon icon={faHeart} className="coeur" />}
                    <div className="article relative">
                        <Link to={post.fields.slug} itemProp="url">
                          <div className="textes_article">
                            <h2>
                              <Link to={post.fields.slug} itemProp="url">
                                <span itemProp="headline">{title}</span>
                              </Link>
                            </h2>
                            <div className="separator relative"></div>
                            <p className="pays relative">{post.frontmatter.ville}</p>
                            <div className="hover">
                              <p className="titre_date">Date de la sortie</p>
                              <p className="date_voyage relative">{post.frontmatter.datevoyage}</p>
                            </div>
                              <p className="tarif relative">dès <span className="prix">{post.frontmatter.tarif}</span> TTC</p>
                          </div>
                          <img src={post.frontmatter.featuredimage} alt={post.frontmatter.altimage} />
                          {post.frontmatter.complet && <div className="background_gradient_complet"><span>Complet</span></div>}
                          <div className="background_gradient"></div>
                        </Link>
                    </div>
                  </article>
                </li>
              </div>
            )
          })}
        </ol>
      </div>
    </Layout>
  )
}

export default EscapadesIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "escapades-post"}}}, sort: {fields: [frontmatter___date], order: DESC}) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(locale: "fr", fromNow: true)
          title
          pays
          featuredimage
          tarif
          datevoyage
          templateKey
          promo
          pourcentpromo
          coeur
          complet
        }
      }
    }
  }
`